import React  from "react";
import { Redirect } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";

import Parameters from "parameters";

import LoginForm from "./LoginForm";

import "./Login.scss";
import logo from "images/logo couleur.png";
import image from "images/urn_aaid_sc_US_e462e56d-a471-434e-a46e-3bb3d3d19841.png";
import { useSnackbar } from 'notistack';

import { AuthConsumer } from "shared/src/contexts/AuthContext";
import {Grid, Typography} from "@material-ui/core";
import {Locale} from "ias-lib";

import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: '#FFFFFF',
            height: '100vh'
        },
        form_part: {
            height: '100%',
            boxShadow: '2px 0 2px 1px white',
            zIndex: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        form: {
            height: '50%',
            width: '50%',
            textAlign: 'left',
        },
        image_part: {
            maxHeight: '100vh',
            overflow: 'hidden',
        },
        title: {
            marginTop: '22px',
            fontFamily: 'Eesti, sans-serif',
            fontSize: '36px',
            paddingBottom: '15px',
        },
    })
);
type RouteState = {
    from: string;
}
type Props = RouteComponentProps<{}, {}, RouteState>

type AuthConsumerVar = {
    isAuth: boolean;
    updateAuthToken: (arg: any) => void;
}

export default function Login({ location }: Props) {
    const classes= useStyles();
    const {enqueueSnackbar} = useSnackbar();

    return (
        <AuthConsumer>
            {({ isAuth, updateAuthToken }: AuthConsumerVar) =>
                isAuth ? (
                    <Redirect to={"/"} />
                ) : (
                    <div>
                        <Grid container className={clsx(classes.root)}>
                            <Grid item className={clsx(classes.form_part)} xs={6}>
                                <div className={clsx(classes.form)}>
                                <img width="162px"  src={logo} alt="Logo" />
                                    <Typography variant="h2" gutterBottom className={clsx(classes.title)}>
                                        {Locale.trans('login.title')}
                                    </Typography>
                                <LoginForm
                                    onLogin={async (login: string, password: string) => {
                                        try {
                                            const res = await fetch(`${Parameters.ApiUrl}auth-tokens`, {
                                                method: "POST",
                                                headers: {
                                                    Accept: "application/json",
                                                    "Content-Type": "application/json"
                                                },
                                                body: JSON.stringify({login, password})
                                            });

                                            if (!res.ok) {
                                                throw Error(res.statusText);
                                            }
                                            const data = await res.json();

                                            setTimeout(()=>{
                                                updateAuthToken(data);
                                            }, 100)
                                        } catch(exception) {
                                            enqueueSnackbar(Locale.trans('login.error.invalid'), {variant: 'error'});
                                        }
                                    }}
                                />
                                </div>
                            </Grid>
                            <Grid item className={clsx(classes.image_part)} xs={6}>
                                <div className="logo">
                                    <img src={image} alt="Logo" height="100%" width={"100%"} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                )
            }
        </AuthConsumer>
    );
}
